import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import {
  ADDRESS_SELECT,
  ADDTIONAL_SHIPPING_OPTION_SELECT,
  ADDRESS_SAVE,
  GUEST_SAVE,
  notifyAddressSelected,
  notifyAdditionalShippingOptionSelected,
  notifyAddressSaved,
  checkoutInfoUpdated,
  notifyAddressSaveFailed,
  notifyGuestAddressSaveFailed,
} from './actions';
import { switchMap, map, mergeMap, takeUntil } from 'rxjs/operators';
import { retryWithToast, catchApiErrorWithToast } from 'behavior/errorHandling';
import {
  getSelectAddressMutation,
  getSelectAdditionalShippingOptionMutation,
  getSaveAddressMutation,
  saveGuestMutation,
  getReloadTemplateFieldsQuery,
} from './queries';
import { adjustShippingMethodData, adjustPaymentMethodData, adjustGuestProfileData, adjustCheckoutAddresses, navigateOnIncorrect } from './helpers';
import { skipIfPreview } from 'behavior/preview';
import { LOCATION_CHANGED } from 'behavior/events';

export default function createEpic(waitForSubmit) {
  return function (action$, state$, { api, logger }) {
    const isQuote = () => state$.value.page.info?.isQuote || false;
    const isPromotion = () => !!state$.value.page.info?.quote;

    const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));

    const reloadAddress$ = api.graphApi(getReloadTemplateFieldsQuery(isPromotion())).pipe(
      map(({ checkout, viewer }) => {
        adjustCheckoutAddresses(checkout, viewer);
        checkout.stepInvalid = true;
        return checkoutInfoUpdated(checkout);
      }),
    );

    const selectAddress$ = action$.pipe(
      ofType(ADDRESS_SELECT),
      skipIfPreview(state$),
      switchMap(({ payload }) => waitForSubmit(() => api.graphApi(getSelectAddressMutation(isPromotion()), {
        id: payload.id,
        options: payload.additionalShippingOptions,
        asQuote: isQuote(),
        maxLines: state$.value.settings.checkout.maxOverviewLines + 1,
      }).pipe(
        mergeMap(({ checkout }) => {
          if (checkout) {
            const selectResult = checkout.address.select;
            adjustShippingMethodData(selectResult.info);
            adjustPaymentMethodData(selectResult.info);
            selectResult.info.pickupLocations = null;

            if (selectResult.success)
              return of(notifyAddressSelected(payload.id, selectResult.info));

            return reloadAddress$;
          }

          return of(navigateOnIncorrect(state$.value.page.info));
        }),
        retryWithToast(action$, logger),
      ))),
    );

    const selectAdditionalShippingOption$ = action$.pipe(
      ofType(ADDTIONAL_SHIPPING_OPTION_SELECT),
      skipIfPreview(state$),
      switchMap(({ payload }) => waitForSubmit(() => api.graphApi(getSelectAdditionalShippingOptionMutation(isPromotion()), {
        input: payload,
        asQuote: isQuote(),
        maxLines: state$.value.settings.checkout.maxOverviewLines + 1,
      }).pipe(
        mergeMap(({ checkout }) => {
          if (checkout) {
            const result = checkout.address.selectShippingOption;
            adjustShippingMethodData(result.info);

            return of(notifyAdditionalShippingOptionSelected(result.info));
          }

          return of(navigateOnIncorrect(state$.value.page.info));
        }),
        retryWithToast(action$, logger),
      ))),
    );

    const saveAddress$ = action$.pipe(
      ofType(ADDRESS_SAVE),
      skipIfPreview(state$),
      switchMap(action => waitForSubmit(() => api.graphApi(getSaveAddressMutation(isPromotion()), {
        input: action.payload,
        asQuote: isQuote(),
        maxLines: state$.value.settings.checkout.maxOverviewLines + 1,
      }).pipe(
        mergeMap(({ checkout }) => {
          if (checkout) {
            const saveResult = checkout.address.save;
            const validationResult = {
              isValid: saveResult.isValid,
              suggestedAddress: saveResult.suggestedAddress,
            };

            if (saveResult.success && saveResult.isValid) {
              adjustShippingMethodData(saveResult.info);
              adjustPaymentMethodData(saveResult.info);
              saveResult.info.pickupLocations = null;
              saveResult.address.address.fields = action.payload.fields;
              return of(notifyAddressSaved(saveResult.address.address, saveResult.info, validationResult));
            } else if(!saveResult.isValid){
              return of(notifyAddressSaveFailed(validationResult));
            }

            return reloadAddress$;
          }

          return of(navigateOnIncorrect(state$.value.page.info));
        }),
        catchApiErrorWithToast(undefined, reloadAddress$),
        retryWithToast(action$, logger),
        takeUntil(locationChanged$),
      ))),
    );

    const saveGuest$ = action$.pipe(
      ofType(GUEST_SAVE),
      skipIfPreview(state$),
      switchMap(action => waitForSubmit(() => api.graphApi(saveGuestMutation, {
        input: action.payload,
        maxLines: state$.value.settings.checkout.maxOverviewLines + 1,
      }).pipe(
        map(({ checkout }) => {
          if (checkout && checkout.address.saveGuest) {
            const saveGuestResult = checkout.address.saveGuest;
            if (saveGuestResult.success) {
              const checkoutInfo = saveGuestResult.info;
              adjustShippingMethodData(checkoutInfo);
              adjustPaymentMethodData(checkoutInfo);
              adjustGuestProfileData(checkoutInfo);
              checkoutInfo.pickupLocations = null;

              const newAddress = checkoutInfo.shippingAddress.address;
              checkoutInfo.shippingAddress = {
                ...state$.value.page.info.shippingAddress,
                shippingOption: checkoutInfo.shippingAddress.shippingOption,
                address: {
                  ...state$.value.page.info.shippingAddress.address,
                  ...newAddress,
                  fields: action.payload.shippingFields,
                },
              };
              checkoutInfo.validationResult = saveGuestResult;
              checkoutInfo.profileFields = action.payload.fields;

              return checkoutInfoUpdated(checkoutInfo);
            } else {
              return notifyGuestAddressSaveFailed(saveGuestResult);
            }
          }

          return navigateOnIncorrect(state$.value.page.info);
        }),
        catchApiErrorWithToast(undefined, reloadAddress$),
        retryWithToast(action$, logger),
        takeUntil(locationChanged$),
      ))),
    );

    return merge(selectAddress$, selectAdditionalShippingOption$, saveAddress$, saveGuest$);
  };
}
