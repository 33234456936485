import type { SingleSignOnProvider } from './types';
import { LogLevel, PublicClientApplication } from '@azure/msal-browser';

export const createClientApp = (provider: SingleSignOnProvider) => {
  const clientConfig = buildClientConfig(provider);
  return new PublicClientApplication(clientConfig);
};

const buildClientConfig = ({ clientId, redirectUri, authority }: SingleSignOnProvider) => {
  return {
    auth: {
      clientId,
      authority,
      redirectUri,
    },
    cache: {
      // Local storage to login if multiple tabs are opened. Default is session storage.
      cacheLocation: 'localStorage',
    },
  };
};