import type { AuthenticationResult, PublicClientApplication } from '@azure/msal-browser';
import type { User } from 'behavior/user/types';
import type { SingleSignOnSettings } from './types';
import { from, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { forgetExternalAccount, getRememberedExternalAccount, rememberExternalAccount } from './cache';
import { createClientApp } from './clientApp';

let clientApp: PublicClientApplication | undefined;

export default () => ({
  enable,
  loginExternalUser,
  logout,
  disable,
});

export const initWithCustomClient = (customClientApp: PublicClientApplication) => clientApp = customClientApp;

const enable = ({ provider }: SingleSignOnSettings) => clientApp = createClientApp(provider);

const disable = () => clientApp = undefined;

const loginExternalUser = () => {
  const loginRequest = {
    scopes: ['openid', 'profile', 'User.Read'],
  };

  return from(clientApp!.loginPopup(loginRequest)).pipe(
    map(loginResponse => ({
      externalLoginResult: toExternalLoginResult(loginResponse),
      handleSanaLogin: handleSanaLogin(clientApp!, loginResponse),
    })),
  );
};

const handleSanaLogin = (clientApp: PublicClientApplication, loginResponse: AuthenticationResult) => (existingUser: User | null) => {
  const { account } = loginResponse;
  if (!!existingUser) {
    rememberExternalAccount(account);
    return;
  }

  // External user was rejected by Sana, we must log out from SSO provider.
  clientApp.logoutPopup({ account });
};

const toExternalLoginResult = ({ idToken }: AuthenticationResult) => {
  return {
    idToken,
  };
};

const emptyEmit = void 0;

const logout = () => {
  if (!clientApp || clientApp.getAllAccounts().length === 0)
    return of(emptyEmit);

  const accountId = getRememberedExternalAccount();
  if (accountId === null)
    return of(emptyEmit);

  const account = clientApp.getAccountByHomeId(accountId);
  if (account === null)
    return of(emptyEmit);

  return from(clientApp.logoutPopup({ account })).pipe(
    tap(_ => forgetExternalAccount()),
  );
};