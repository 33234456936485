import styles from 'components/objects/basket/lines/Lines.module.scss';
import PropTypes from 'prop-types';
import { RichText } from 'components/sanaText';
import InfoAlert from './InfoAlert';

const BackorderItemMessage = ({ productLineId, backorderQuantity }) => {
  if (backorderQuantity <= 0)
    return null;

  return (
    <InfoAlert className={styles.backorderMessage} id={`backorderItemMessage_${productLineId}`}>
      <RichText textKey="BackorderItemMessage" formatWith={[backorderQuantity]} />
    </InfoAlert>
  );
};

BackorderItemMessage.propTypes = {
  productLineId: PropTypes.string.isRequired,
  backorderQuantity: PropTypes.number.isRequired,
};

export default BackorderItemMessage;