export const USER_EDIT_PROFILE = 'USER/EDIT_PROFILE';
export const editProfile = customerData => ({
  type: USER_EDIT_PROFILE,
  payload: { customerData },
});

export const USER_EDIT_PROCESSED = 'USER/EDIT_PROCESSED';
export const processedEditProfile = editProfileResult => ({
  type: USER_EDIT_PROCESSED,
  payload: { editProfileResult },
});