import {
    USER_EDIT_PROCESSED,
  } from './actions';

const initialState = {
    editProfileResult: {
      isRegistered: false,
      isValidAddress: false,
      suggestedAddress: null,
    },
    templateFields: null,
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case USER_EDIT_PROCESSED:
        return { ...state, ...action.payload };
      default:
        return state;
    }
  };